@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family:   -apple-system,BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(95deg,rgba(0,0,0) ,rgba(61,96,152,1) ,rgba(33,49,89));
  position: relative;
  min-height: 100vh;
  /*I did this to stop the mui basic menu from setting padding right to 8px I cannot find where exactly
  that is happening and it doesnt seem to be something I implemented I will look for a better solution 
  but it works for now with seemmingly no negative effects*/ 
  padding-right: 0px !important;
}
pre {
  margin: unset;
  font-family: 'Courier New', Courier, monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.resource-pic{
  width:100%;
  height:100%;
  border-radius:10px;
}
.resources-nav a.active{
  color: white
}

#portFolioSwiper{
  width:100%;
  height:auto !important;
  //box-shadow:0 0 10px white !important;
  
}
#ReviewsSwiper{
  width:95%;
  height: auto !important;
 //margin: 8em 0em 4em 0em !important;
  //box-shadow:0 0 10px white !important;
  
}


#stackBrands{
 height: 17vh !important;
 background: linear-gradient(195deg,rgba(0,0,0) ,rgba(61,96,152,1) ,rgba(0,0,0));
 //border: 1px solid white;
 //box-shadow: 0 0 10px white;
 border-radius: 7px;
 width: 60%
  }

.mySwiper{
 width:100%;
 box-shadow: 0 0 10px rgb(0, 0, 0);
}
.swiperButton{
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  background: linear-gradient(rgba(61,96,152,.25),rgba(33,49,89,.45));
  cursor: zoom-in;
  border: none;
}
.swiper-img{
  max-width: 100%;
object-fit: cover;
}
.modal-img{
  max-width: 100%;
object-fit: contain;
}
video{
max-width: 100%;
object-fit: contain;
}

.MuiList-root{
  background-color: rgba(61, 96, 152,0.7);
}
.MuiMenuItem-root{
  font-family: 'Courier New', Courier, monospace !important;
}

/*New swiper stuff 2-26-23 portfolio*/
.swiper-wrapper{
  padding-inline-start:0;
  height: 100% !important;
}
/*added this within user profile it could effect other swipers but doesnt seem too at this point*/
.swiper{
  height: 100% !important;
}
/*made this class fo swiper in profile page*/
.quesSwiper{
  width:100%;
  border: 1px solid black;
  box-shadow: 0 0 10px rgb(0, 0, 0);
}


.ques-swiper-slide{
height:auto !important; 
}
.pictureHome{
  width:100%;
  height:100%;
  border-radius: 10px;
}
.road-map-pic{
  width: 100%;
  height:100%;
  object-fit:fill;
  border-radius: 11px;
  /*border: 1px solid white;
  box-shadow: 0 0 10px white;
  &:hover{
    border: 1px solid #fee084;
    box-shadow: 0 0 10px #fee084;
   
 }*/
}

.brand-pic{
  width:100%;
  height: 100%
}

#vertical-brand-slide{
  height: 100% !important;
}
/**aug 23 2023**/
.MuiPaper-root{
  background: rgba(33,49,89) !important;
}

/****************!!!!!!!!!!!!!!!MEDIA QUERIES!!!!!!!!!!!!!!********************/
@media screen and (min-width: 360px) {
  #stackBrands{
    width:50%;
    }
}

@media screen and (min-width: 540px) {
  #stackBrands{
  width:40%;
  }
}

/*medie querie min-width 768px */
@media screen and (min-width: 768px) {
  .mySwiper{
    width: 75%;
  }
 
  video{
    width:100%;
     height: auto;
  }

  .quesSwiper{
    width: 75%;
  }

  #stackBrands{
    width:25%;
    height: 11vh !important;
    }
}

@media screen and (min-width: 820px) {
  .mySwiper{
    width: 80%;
  }


  .quesSwiper{
    width: 80%;
    
  }
}

@media screen and (min-width: 1024px) {
  .mySwiper{
    width: 50%;
    
  }

  .quesSwiper{
    width: 50%;
    
  }

  /*id added to take precendence over other swiper widths since this class is shared throughout app*/
  #portFolioSwiper{
    height:100% !important;
    
    
  }
  #ReviewsSwiper{
    height:100% !important;
    box-shadow:0 0 10px white !important;
    
  }
}

@media screen and (min-width: 1366px) {
  #stackBrands{
    height: 15vh !important;
    }
}

@media screen and (min-width: 1920px) {
  #stackBrands{
    height: 22vh !important;
    width:25%
  }
}
/****************!!!!!!!!!!!!!!!MEDIA QUERIES END!!!!!!!!!!!!!!********************/


